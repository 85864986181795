import React from 'react'

import '../../static/styles/form.css'

export default ({ block }) => {

  const onSubmit = (e) => {
    e.preventDefault()
    // making the callback function global so that it could be recognized by g-recaptcha
    window.onChange = onChange
    // executing recaptcha
    if (!grecaptcha.ready) {
      grecaptcha.ready(function () {
        grecaptcha.render('g-recaptcha', {
          sitekey: process.env.GATSBY_RECAPTCHA_SITE_KEY,
        })
      })
    }

    grecaptcha.execute()
  }

  // this function only runs if the recaptcha challenge is successful
  function onChange() {
    // submitting the form
    document.getElementById('newsletter-' + block?._id).submit()
  }

  // load ecaptcha only when needed. Recaptcha has a massive performance impact
  function loadReCaptcha() {
    var head = document.getElementsByTagName('head')[0]
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://www.google.com/recaptcha/api.js'
    head.appendChild(script)
  }


  return (
    <section className="subscribe-to-newsletter">
        {block?.content?.title &&
          <h2>{block.content.title}</h2>
        }

        <form
          id={'newsletter-' + block?.id}
          onSubmit={onSubmit}
          action={block?.content?.form_action}
          data-async="true"
          data-recaptcha="false"
          method="POST"
          enctype="multipart/form-data"
          className="bento-formkit"
        >
          <div
            data-sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
            data-callback="onChange"
            className="g-recaptcha"
            data-size="invisible"
          />
          {block?.content?.form_url &&
            <input
              type="hidden"
              name="redirect"
              value={block?.content?.form_url}
            />
          }
          <input
            onFocus={(e) => loadReCaptcha()}
            type="text"
            name="fields_first_name"
            className="bento-formkit-input"
            placeholder="Your name"
            required
          />
          <input
            onFocus={(e) => loadReCaptcha()}
            type="email"
            name="email"
            placeholder="Your email"
            className="bento-formkit-input"
            required
          />
          <button type="submit" className="bento-formkit-button btn btn-primary purple-btn">Sign-up</button>
        </form>

        <div className="newsletter-recaptcha-text">
          This site is protected by reCAPTCHA and the Google
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="nofollow"
          >
            {' '}
            Privacy Policy
          </a>{' '}
          and
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="nofollow"
          >
            {' '}
            Terms of Service
          </a>{' '}
          apply.
        </div>

    </section>
  )
}

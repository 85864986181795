import React, { useState, useEffect } from "react"
import { useStoryblokState } from "gatsby-source-storyblok";
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Image from "utils/Image"
import Form from '../components/Form';
import RichTextRenderer from 'utils/RichTextRenderer'
import { storyblokEditable } from "gatsby-source-storyblok"
import Seo from "components/Seo"

import '../../static/styles/block-hero.css';
import '@fontsource/open-sans/700.css';

const LandingPage = ({
  data: {
    landingPage: page
  },
  path,
}) => {
  // This will be used to set live editor preview data
  const [previewStory, setPreviewStory] = useState(null);

  // Parse Storyblok data
  let story = page ? useStoryblokState(page, { resolveRelations: ["landing-page.blocks"] }) : undefined;

  // I can't remember why this was needed, so let's first test the Storyblok editor experience with it and then without it
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {
      setPreviewStory(null);
    }
  }, [story]);

  // If inside Storyblok editor, execute this
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {

      // Storyblok API endpoint
      const url = `https://api-us.storyblok.com/v2/cdn/stories/${page?.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_TOKEN}&cv=${Date.now()}&resolve_relations=landing-page?.blocks`;

      // Fetch preview data from Storyblok API
      const fetchData = async () => {
        try {
          const response = await fetch(url);
          const json = await response.json();
          setPreviewStory(json.story);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();

      // Let's wait until the Storyblok script has loaded
      const waitForStoryblokToLoad = function () {
        if (!window.StoryblokBridge) {
          setTimeout(waitForStoryblokToLoad, 100)
        } else {
          const { StoryblokBridge } = window
          const storyblokInstance = new StoryblokBridge()

          // Update live preview when Storyblok story changes
          storyblokInstance.on('input', (event) => {
            setPreviewStory(event.story);
          })
        }
      }

      waitForStoryblokToLoad();
    }
  }, []);

  // Conditional that checks whether to show original GraphQL data or the preview data
  const content = previewStory ? previewStory?.content : story?.content;

  const description = get(
    content,
    'description',
    `${page?.name} - Click to read on.`
  );

  const transformVariables = (variables, content) => {
    if (!variables || !content || typeof content !== 'string') return content;

    variables.forEach((info) => {
      content = content?.split('%' + info.name + '%').join(info.value);
    });

    return content;
  };

  const blockContent = (block, transformedContent, index) => {
    switch (block.content?.component) {
      case 'block-hero':
        return (
          <div
            className={`diagonal-group${index === 0 && ' main-banner'}`}
            key={block.slug}
            style={{ paddingTop: '3em' }}
          >
            <div className="container padded">
              <section
                className="row text-center hero"
                style={index !== 0 ? { marginBottom: '6em' } : {}}
              >
                <div className="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 mx-auto">
                  <h1>
                    {transformVariables(
                      content?.variables,
                      block.content?.heading
                    )}
                  </h1>
                  <h4
                    className="mb-4"
                    dangerouslySetInnerHTML={{
                      __html: transformedContent,
                    }}
                  />
                  {block.content?.button_url && (
                    <a
                      title={block.content?.button_text}
                      href={block.content?.button_url}
                      // target="_blank"
                      rel="noreferrer"
                      pointerEvents="none"
                    >
                      <button className="btn btn-primary purple-btn">
                        {block.content?.button_text}
                      </button>
                    </a>
                  )}
                </div>
              </section>
            </div>
          </div>
        )
      case 'block-content':
        return (
          block.content?.image ? (
            <section className="image-content">
              <div className="container padded">
                {block.content?.image_position === 'right' &&
                  /* CSS below fixes a bug where that specific media query is not being generated on build time from the CSS file */
                  <style>
                    {`
                      .image-content__image {
                        position: relative;
                        height: 100%;
                      }
                      @media (min-width: 768px) {
                        .image-content__inner {
                          grid-template-columns: repeat(2, 1fr);
                          align-items: center;
                        }
                      
                        .image-content__image--right {
                          grid-column: 2/3;
                          grid-row: 1/2;
                        }
                      }
                    `}
                  </style>
                }
                <div className="image-content__inner">
                  {block.content?.image && (
                    <div className={`image-wrapper image-content__image image-content__image--${block.content?.image_position}`}>
                      <Image img={block.content?.image} style={{ objectFit: 'contain' }} />
                    </div>
                  )}
                  <div className="image-content__content">
                    <h2 className="image-content__heading">
                      {transformVariables(
                        content?.variables,
                        block.content?.heading
                      )}
                    </h2>
                    <div
                      {...storyblokEditable(transformedContent)}
                      className="image-content__text"
                    >
                      <RichTextRenderer data={transformedContent} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <div className="diagonal-group" key={block.slug}>
              <div className="container padded">
                <section
                  style={{
                    marginBottom: '1.5em',
                    marginTop: '2em',
                    textAlign: 'justify',
                  }}
                >
                  <div className="row">
                    <div className="col-12 col-lg-10 col-xl-8 mx-auto">
                      <h2 className="heading">
                        {transformVariables(
                          content?.variables,
                          block.content?.heading
                        )}
                      </h2>
                    </div>
                    <div
                      {...storyblokEditable(transformedContent)}
                      className="col-12 col-lg-10 col-xl-8 mx-auto"
                    >
                      <RichTextRenderer data={transformedContent} />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          )
        )
      case 'block-quote':
        return (
          <div className="diagonal-group" key={block.slug}>
            <div className="container padded">
              <section
                style={{
                  marginBottom: '1.5em',
                  marginTop: '5em',
                }}
              >
                <div className="row">
                  <div className="col-10 col-lg-8 col-xl-7 mx-auto text-center">
                    <blockquote>
                      &ldquo;{block.content?.content}&rdquo;
                    </blockquote>
                  </div>
                </div>

                <div className="row testimonial">
                  <div className="col-6 text-right">
                    {block.content?.image && (
                      <Image
                        img={block.content?.image}
                        style={{
                          marginLeft: 'auto',
                          marginTop: '0px',
                          marginBottom: '-120px',
                          width: '128px',
                          height: '128px',
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                      />
                    )}
                  </div>
                  <div className="col-6 vertical-center">
                    <div className="author">
                      {block.content?.author}
                    </div>
                    <div className="description">
                      {block.content?.description}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )
      case 'block-cta':
        return (
          <div
            key={block.slug}
          >
            <div className="container padded">
              <section className="row" id="final-cta">
                <div className="col-12">
                  <div className="cta-card">
                    <div className="row">
                      <div className="col-12 col-md-6 offset-xl-1 col-xl-5">
                        <h2>
                          {transformVariables(
                            content?.variables,
                            block.content?.heading
                          )}
                        </h2>
                        <p>
                          {transformVariables(
                            content?.variables,
                            block.content?.content
                          )}
                        </p>
                      </div>
                      <div className="col-12 col-md-6 offset-xl-1 col-xl-3 d-flex flex-column justify-content-center align-items-start align-items-md-center align-items-xl-end">
                        <div className="link-group">
                          <div className="d-flex justify-content-between g2-badge-container">
                            <div className="cta-image-wrapper image-wrapper" style={{ width: '400px', height: '109px' }}>
                              {block.content?.image && (<Image img={block.content?.image} style={{ objectFit: 'contain' }} />)}
                            </div>
                          </div>
                          <a
                            title={block.content?.button_text}
                            href={transformVariables(
                              content?.variables,
                              block.content?.button_url
                            )}
                            className="btn btn-primary purple-btn"
                          >
                            {block.content?.button_text}
                          </a>
                          <small>
                            {block.content?.button_description}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )
      case 'blockform':
        return (
          <Form block={block} />
        )
      default:
        return (
          <div key="unknown"> {block.content?.component} </div>
        )
    }
  }

  const blockContents = content?.blocks?.map((block, index) => {
    let transformedContent = ''
    if (block.content?.content) {
      transformedContent = transformVariables(
        content?.variables,
        block.content?.content
      )
    }

    return blockContent(block, transformedContent, index);
  });

  return (
    <>
      <Seo
        title={`${page?.name} | QuickMail`}
        description={description}
        noindex={content?.no_index == "true"}
        url={content?.directory ?
            `${content?.directory}/${page?.slug}`
            :
            page?.slug
        }
      />
      <div {...storyblokEditable(content)} id="landingpage">
        {blockContents}
      </div>
    </>
  );
};

export default LandingPage;

export const LandingPageQuery = graphql`
  query LandingPageBySlug($slug: String!) {
    landingPage: storyblokEntry(slug: { eq: $slug }) {
      name
      content
      slug
    }
  }
`;
